<template>
  <BuyHotelUI @searchHotel="searchHotel" @chooseCity="chooseCity"/>
</template>

<script>
import BuyHotelUI from "./components/BuyHotelUI";
export default {
  name: "BuyHotel",
  components: { BuyHotelUI },
  methods: {
    // 搜索按钮
    searchHotel() {
      this.$router.push("/searchHotel")
    },

    // 选择城市
    chooseCity(vuxFunction = "hotel/setHotelOrderFrom"){
        this.$router.push({
          path: "/selectCity",
          query: { function: vuxFunction }
        });
    }
  },
};
</script>